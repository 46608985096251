import {
  NEWBOOKING,
  ADDFIELDS,
  PAYMENTS,
  ORDERLIST,
  BOOKDETAILS,
  DELETEBOOKING,
  STRIPECHARGES,
  UPDATEBOOKING,
    ASYNC_START
  } from "../constants/actionTypes";
  // import { Spin } from 'antd';
  const defaultState = {
    newbookinglist: {},
    additionalfields: {},
    paymentDetails: {},
    orderlist: {},
    bookDetails: {},
    deletebooking:{},
    stripeCharges:{},
    updateBooking: {}
  };
  export default (state = defaultState, action) => {
    switch (action.type) {
      case NEWBOOKING:
        return {
          ...state,
          innewbookingProgress: false,
          newbookinglist: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case ADDFIELDS:
        return {
          ...state,
          inaddfieldsProgress: false,
          additionalfields: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case PAYMENTS:
        return {
          ...state,
          inpaymentProgress: false,
          paymentDetails: action.error ? { ...action.payload.error, showMessage: false, } : { ...action.payload, showMessage: true, }
        };
        case ORDERLIST:
        return {
          ...state,
          inorderProgress: false,
          orderlist: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case BOOKDETAILS:
        return {
          ...state,
          inbookDetailsProgress: false,
          bookDetails: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case DELETEBOOKING:
          return {
            ...state,
            indeleteBookProgress: false,
            deletebooking: action.error ? null : { ...action.payload, showMessage: true, }
          };
          case STRIPECHARGES:
            return {
              ...state,
              instripeChargesProgress: false,
              stripeCharges: action.error ? action.error : { ...action.payload, showMessage: true, }
            };
            case UPDATEBOOKING:
              return {
                ...state,
                inupdateBookingProgress: false,
                updateBooking: action.error ? null : { ...action.payload, showMessage: true, }
              };
      case ASYNC_START:
        if (action.subtype === NEWBOOKING) {
          return { ...state, innewbookingProgress: true };
        }
        if (action.subtype === ADDFIELDS) {
          return { ...state, inaddfieldsProgress: true };
        }
        if (action.subtype === PAYMENTS) {
          return { ...state, inpaymentProgress: true };
        }
        if (action.subtype === ORDERLIST) {
          return { ...state, inorderProgress: true };
        }
        if (action.subtype === BOOKDETAILS) {
          return { ...state, inbookDetailsProgress: true };
        }
        if (action.subtype === DELETEBOOKING) {
          return { ...state, indeleteBookProgress: true };
        }
        if (action.subtype === STRIPECHARGES) {
          return { ...state, instripeChargesProgress: true };
        }
        if (action.subtype === UPDATEBOOKING) {
          return { ...state, inupdateBookingProgress: true };
        }
        break;
    }
    return state;
  };
  