import React from "react";
import {MailOutlined,PhoneOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom'
import "./header.css";
import Logo from '../../../assets/img/Logo.png'


class PageHeader extends React.Component {
    render() {

        return (
            <div className="PageHeaderContainer">
	            <div className="container">
	            <div className="HeaderInnerContainer">
					<div className="HeaderLogo">
						<Link to="/"><img src={Logo} alt="Logo" /></Link>
					</div>
					<div className="HeaderContactInfo">
						<span className="ContactInfoMenu">
						    <span className="ContactInfoMenuIcon"><i className="sb-mail" /></span>
							<span>admin@safe-buy.com.au</span>
						</span>
						<span className="ContactInfoMenu">
						    <span className="ContactInfoMenuIcon"><i className="sb-call" /></span>
							<span>03 8676 7692</span>
						</span>
					</div>
					</div>
				</div>
      		</div>
        );
    }
}

export default PageHeader;