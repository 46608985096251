import {
  LOGIN,
  ASYNC_START,
  DISTANCE
} from "../constants/actionTypes";
// import { Spin } from 'antd';
const defaultState = {
  signupDetails: {},
  distance:{}
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        inProgress: false,
        signupDetails: action.error ? null : { ...action.payload, showMessage: true, }
      };
      case DISTANCE:
      return {
        ...state,
        inDistanceProgress: false,
        distance: action.error ? null : { ...action.payload, showMessage: true, }
      };
    case ASYNC_START:
      if (action.subtype === LOGIN) {
        return { ...state, inProgress: true };
      }
      if (action.subtype === DISTANCE) {
        return { ...state, inDistanceProgress: true };
      }
      break;
  }
  return state;
};
