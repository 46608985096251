

export const LOGIN = "LOGIN";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const REDIRECT = "REDIRECT";
export const DISTANCE = "DISTANCE";
/**Services types */
export const SERVICELIST = "SERVICELIST";
export const SERVICEVIEW = "SERVICEVIEW";
export const PRODUCTLIST = "PRODUCTLIST";
export const PRODUCTVIEW = "PRODUCTVIEW";
export const COUPON = "COUPON";
export const PROVIDERLIST = "PROVIDERLIST";

/**Clients */
export const CREATECLIENT = "CREATECLIENT";

/**Booking slots */
export const SLOTS = "SLOTS";
export const NEWBOOKING = "NEWBOOKING";
export const ADDFIELDS = "ADDFIELDS";
export const ORDERLIST = "ORDERLIST";
export const PAYMENTS = "PAYMENTS";
export const BOOKDETAILS = "BOOKDETAILS";
export const DELETEBOOKING = "DELETEBOOKING";
export const STRIPECHARGES = "STRIPECHARGES";
export const UPDATEBOOKING = "UPDATEBOOKING";

/**Client Booking  */
export const CLIENTTOKEN = "CLIENTTOKEN";
export const CLIENTBOOKING = "CLIENTBOOKING";
 
