
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { ThroughProvider } from "react-through";

import { store, history } from "./core-units/config/storeConfig";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from './core-units/routes/windowScroll'
import App from "./containers/App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "../node_modules/jquery/dist/jquery.js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "../node_modules/antd/dist/antd.css";


// const ScrollToTop = () => {
//   window.scrollTo(0, 0);
//   return null;
// };


const theApp = () => {
  return (
    <ThroughProvider>
      <App />
    </ThroughProvider>
  );
};

history.listen(_ => {
  window.scrollTo(0, 0)  
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>

    <ScrollToTop />
    
      <Switch>

      {/*<Route component={ScrollToTop}/>*/}

        <Route path="/" component={theApp} />
        
      </Switch>
    </ConnectedRouter>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
