import React, { useEffect, useState } from "react";
import { Select, Input, Form, Button, message, Modal, Checkbox, Spin } from "antd";
import { BrowserRouter as Router, Switch, Link } from "react-router-dom";
import "../home-page.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import agent from '../../../agent';
import { GlobalVariable } from "../../../core-units/config/generalConfig";
import { LOGIN, SERVICELIST, DISTANCE } from '../../../core-units/constants/actionTypes';
import bannerBG from "../../../assets/img/bannerBG.png";
import _, { isEmpty } from "lodash";
import { getDistance } from 'geolib';
import Ripples from 'react-ripples'
import { connect } from "react-redux";
const { Option } = Select;
const Locations = (props) => {
  const [homeLocation, setHomelocation] = useState([-37.840935, 144.946457]);
  const [selectedValuelocation, setSelectedValuelocation] = useState("");
  const [location_search_data, setLocation_search_data] = useState([]);
  const [fetching, setFetching] = useState(false); // alobinTech
  const [locDiff, setLocDiff] = useState(0);
  
  useEffect(() => {
    async function login() {
      let request = {
      }
      props.auth(request, (values) => {
      props.servicesList();
      });
      setLocDiff(localStorage.getItem('diffLoc'));
      setLocation_search_data(localStorage.getItem('location_search_data'));
      setSelectedValuelocation(localStorage.getItem('selectedValuelocation'));
    };
    login();
  }, [fetching]); // alobinTech
  /**Location search */
  const handleChange = address => {
    setSelectedValuelocation(address);
  };

  /**Location select */
  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        locationDifference(latLng);
        setSelectedValuelocation(address);
      })
      .catch(error => console.error('Error', error));
  };
  const locationDifference = latLng => {
    const latLngD = [latLng.lat, latLng.lng]
    setLocation_search_data(latLngD);
  }
  /**Submit function  */
  const onSubmit = () => {
    if(!isEmpty(selectedValuelocation)|| !_.isEmpty(localStorage.getItem('selectedValuelocation'))){
      localStorage.removeItem('value')
      localStorage.removeItem('service')
      localStorage.removeItem('total')
      localStorage.removeItem('product')
      props.servicesList();
      document.getElementById("sample").scrollIntoView({
        behavior: 'smooth' 
      });
     
      // window.scrollTo(0, 5)
      props.servicesList();
      props.distance({ origin: '585 collins st, Melbourne, VIC 3000', destination: selectedValuelocation }, (value) => {
        const loc = _.get(value, 'routes');
        _.map(loc, function (val, key) {
          _.map(val.legs, function (each, i) {
            const distance = each.distance.value / 1000;
            setLocDiff(distance);
            setFetching(false);
            localStorage.setItem('diffLoc', distance);
          })
  
        })
      });
      localStorage.setItem('selectedValuelocation', selectedValuelocation);
      localStorage.setItem('location_search_data', location_search_data);
      
    }
  
  }

  //Edit Function --------

  const onEdit = () => {  // alobinTech
    if(props.routingValidation.edit === 0){
        localStorage.clear();
        setFetching(true)
    }
    if(props.routingValidation.edit === 1){
      localStorage.clear();
      props.routingValidation.home()
    }
    if(props.routingValidation.edit === 2){
      localStorage.clear();
      props.routingValidation.home()
    }
    if(props.routingValidation.edit === 3){
      localStorage.clear();
      props.routingValidation.home()
    }
    if(props.routingValidation.edit === 4){
      localStorage.clear();
      props.routingValidation.home()
    }
  }

  const render = () => {
    const searchOptions = {
      componentRestrictions: { country: "aus" }
    }
    return (
      <div
        className="BannerSection"
        style={{ backgroundImage: `url(${bannerBG})` }}
      >
        <div className="BannerTextContainer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="BannerTextTitle">
                  <span className="text-nowrap">
                    Whenever you buy
                                    </span>
                  <span className="txt-brown">SafeBuy</span>
                </div>
                <div className="BannerTextDesc">
               <b>Book your inspection by entering the car location (<span className="address">Complete address</span>) in the box below</b> 
                                </div>
                <div className="BannerInputContainer">
                  <Form>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Please fill the location',
                        },
                      ]}>
                      <PlacesAutocomplete
                        value={selectedValuelocation}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        searchOptions={searchOptions}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            {props.routingValidation.route === false && _.isEmpty(localStorage.getItem("selectedValuelocation")) ?
                            <Input
                            {...getInputProps({
                              placeholder: '585 Collins Street, Melbourne , VIC 3000',
                              className: 'location-search-input',
                            })}
                            //disabled={props.routingValidation.route}
                            required
                          /> :
                          <Input
                              {...getInputProps({
                                placeholder: '585 Collins Street, Melbourne , VIC 3000',
                                className: 'location-search-input',
                              })}
                              disabled
                              required
                            />
                            }
                            
                            {_.isEmpty(selectedValuelocation) ? <span className="location-error-message">Please fill this field!</span> : ''}
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                    })}
                                  >
                                    <span key={suggestion.index}>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Item>
                    <Form.Item>
                      {/* {console.log("Length :",props.routingValidation.route)} */}
                    <Ripples className="buttonRipple">
                      {/* {props.routingValidation.route === true ? 
                      <Button onClick={onSubmit} id='sample'>Get Started</Button> :
                      <Button onClick={onSubmit} id='sample' disabled>Get Started</Button>
                      } */}
                      {/* {props.routingValidation.route === false && _.isEmpty(localStorage.getItem("selectedValuelocation")) ? 
                      <Button onClick={onSubmit} id='sample'>Get Started</Button> 
                      : 
                      <Button onClick={onEdit} id='sample' >Edit/Change</Button>} */}
                      <Button onClick={
                        props.routingValidation.route === false && _.isEmpty(localStorage.getItem("selectedValuelocation")) ? onSubmit : onEdit
                        } id='sample'>
                        {props.routingValidation.route === false && _.isEmpty(localStorage.getItem("selectedValuelocation")) ? 'Get Started' : 'Edit / Change'}
                        </Button> 
                      </Ripples>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return render();
}
const mapStateToProps = state => (
  {
  });
const mapDispatchToProps = dispatch => ({
  auth: (params, callback) => dispatch({ type: LOGIN, payload: agent.Auth.login(params), successNotification: false, callback: callback }),
  servicesList: (params, callback) => dispatch({ type: SERVICELIST, payload: agent.Services.list(params), callback: callback }),
  distance: (params, callback) => dispatch({ type: DISTANCE, payload: agent.Auth.google_location(params), callback: callback }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Locations);