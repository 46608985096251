import {
    SERVICELIST,
    PRODUCTLIST,
    COUPON,
    SERVICEVIEW,
    SLOTS,
    CREATECLIENT,
    PROVIDERLIST,
    PRODUCTVIEW,
    ASYNC_START
  } from "../constants/actionTypes";
  // import { Spin } from 'antd';
  const defaultState = {
    serviceList: {},
    productList: {},
    couponlist: {},
    serviceView:{},
    slots:{},
    createclient: {},
    providerList:{},
    productView: {}
  };
  export default (state = defaultState, action) => {
    switch (action.type) {
      case SERVICELIST:
        return {
          ...state,
          inServicesProgress: false,
          serviceList: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case PRODUCTLIST:
        return {
          ...state,
          inProductProgress: false,
          productList: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case COUPON:
        return {
          ...state,
          inCouponProgress: false,
          couponlist: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case SERVICEVIEW:
        return {
          ...state,
          inServiceViewProgress: false,
          serviceview: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case SLOTS:
        return {
          ...state,
          inSlotsProgress: false,
          slots: action.error ? null : { ...action.payload }
        };
        case CREATECLIENT:
        return {
          ...state,
          inclientProgress: false,
          createclient: action.error ? null : { ...action.payload, showMessage: true, }
        };
        case PROVIDERLIST:
          return {
            ...state,
            inproviderProgress: false,
            providerList: action.error ? null : { ...action.payload, showMessage: true, }
          };
          case PRODUCTVIEW:
          return {
            ...state,
            inProductViewProgress: false,
            productView: action.error ? null : { ...action.payload, showMessage: true, }
          };
      case ASYNC_START:
        if (action.subtype === SERVICELIST) {
          return { ...state, inServicesProgress: true };
        }
        if (action.subtype === PRODUCTLIST) {
            return { ...state, inProductProgress: true };
          }
          if (action.subtype === COUPON) {
            return { ...state, inCouponProgress: true };
          }
          if (action.subtype === SERVICEVIEW) {
            return { ...state, inServiceViewProgress: true };
          }
          if (action.subtype === SLOTS) {
            return { ...state, inSlotsProgress: true };
          }
          if (action.subtype === CREATECLIENT) {
            return { ...state, inclientProgress: true };
          }
          if (action.subtype === PROVIDERLIST) {
            return { ...state, inproviderProgress: true };
          }
          if (action.subtype === PRODUCTVIEW) {
            return { ...state, inProductViewProgress: true };
          }
        break;
    }
    return state;
  };
  