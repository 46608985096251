import {
  CLIENTTOKEN,
  CLIENTBOOKING,
    ASYNC_START
  } from "../constants/actionTypes";
  // import { Spin } from 'antd';
  const defaultState = {
    clientToken:{},
    clientbookinglist: {},
  };
  export default (state = defaultState, action) => {
    switch (action.type) {
      case CLIENTTOKEN:
      return {
        ...state,
        inclienttokenProgress: false,
        clientToken: action.error ? null : { ...action.payload, showMessage: true, }
      };
      case CLIENTBOOKING:
        return {
          ...state,
          inclientbookingProgress: false,
          clientbookinglist: action.error ? null : { ...action.payload, showMessage: true, }
        };
        
      case ASYNC_START:
        if (action.subtype === CLIENTTOKEN) {
          return { ...state, inclienttokenProgress: true };
        }
        if (action.subtype === CLIENTBOOKING) {
          return { ...state, inclientbookingProgress: true };
        }
        break;
    }
    return state;
  };
  