import React from 'react';
import LayoutContent from '../Content';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import FooterImg from '../../../assets/img/VACClogo_Corporate.png'
import "./footer.css";

class PageFooter extends React.Component {
	render() {
		return (
			<div className="FooterContainer">
				<div className="container">
					<div className="d-flex justify-content-between">
						<span><a href='/privacy-policy' target='_blank'>Privacy Policy</a> | <a className='footer-terms-conditions' href='https://www.safe-buy.com.au/read-the-terms-conditions-of-prepurchase-vehicle-services/' target='_blank'>Terms and Conditions</a> | <a href='https://safebuy.com.au/booking-refund-and-cancellation-policy/' target='_blank'>Booking & Refund Policy</a></span>
						<span className='FooterImage'><img src={FooterImg} /></span>
						<span>Copyright ©2021 SafeBuy ABN: 17510261621. All Rights Reserved</span>
					</div>
				</div>
			</div>
		);
	}
}

export default PageFooter;