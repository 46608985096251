import React from "react";
import { connect } from "react-redux";
import {
  REDIRECT,
} from "../core-units/constants/actionTypes";
import routes from "../core-units/routes";
import * as queryString from "query-string";
import { withRouter } from 'react-router-dom';

import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../App.css';
import '../assets/fonts/safebuy/style.css';
import '../../node_modules/antd/dist/antd.css';
import '../../node_modules/jquery/dist/jquery.min.js';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

const mapStateToProps = state => {

  return {
  };
};

const mapDispatchToProps = dispatch => ({
  onRedirect: () => dispatch({ type: REDIRECT }),});

class App extends React.Component {
  componentWillReceiveProps(nextProps) {
  }

  componentWillMount() {
  }

  scrolled = false;
  componentDidUpdate(prevProps) {
    const urlQuery = queryString.parse(window.location.search);
    if (this.scrolled === false) {
      window.scrollTo(0, 0);
      this.scrolled = true;
    }

  }

  render() {
      return <div>{routes}</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
