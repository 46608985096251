let host = window.location.host
let hostName = host.split(':')
const baseUrl = `${window.location.protocol}//${hostName[0]}`

export const GlobalVariable = Object.freeze({
  production: false,
  BASE_API_URL: `${baseUrl}/safebuy/`,
  RESOURCE_URL: `${baseUrl}/assets/`,
  MIDDLEWARE_URL: `${baseUrl}:3001/api`,
  LOCATION_KEY:'AIzaSyDsEDx_YJgIEvgP0hXL12RowJNm2nbkrAM',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_51H6zerD27mfUOCUa8OzAL2pA0cWbi2do1Z9E8cSNCMRS6nLhlBhs8z6ef36pkddE2b66vBCSeSB2gJTv299w3MUx00U2AXWvzn',

})
