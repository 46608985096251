import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from '../reducers/auth';
import services from '../reducers/services';
import booking from '../reducers/booking';
import clientBooking from '../reducers/clientbooking';
import { createBrowserHistory as createHistory } from 'history'
export const history = createHistory();

const appReducer = combineReducers({
  auth,
  services,
  booking,
  clientBooking,
  router: connectRouter(history),
});
const rootReducer = (state, action) => {
  // if (action.type === LOGOUT) {
  //   const { router } = state;
  //   state = { router };
  //   // lStorage.clear()
  // }
  return appReducer(state, action)
}


export default rootReducer;