import React from 'react'
import {BrowserRouter as Switch,Route,router} from 'react-router-dom';
import HomePageComponent from '../../../containers/home-page/index';




class LayoutContent extends React.Component {
	render() {
		return (
			<div style={{width:'100%',float: 'left'}}>
			      <Route exact path="/" component={HomePageComponent}/>	         
			</div>
		);
	}
}

export default LayoutContent;